<template>

  <v-container height="100%" fluid :class="{'grey lighten-2': !$vuetify.theme.dark}">

    <!-- HEIGHT FILLER -->
    <v-row class="primary" style="height: 400px">
    </v-row>

    <!-- iDiGi -->
    <idigi-login-card
        v-if="issuerName === 'idigi'"
        v-show="loaded"
        @login="onLoginClicked">
    </idigi-login-card>

    <!-- Normal Login Card -->
    <v-row v-else justify="center" :class="{'grey lighten-2': !$vuetify.theme.dark}">

      <v-col cols="auto" class="py-0">

        <v-fade-transition>

          <v-card v-show="loaded" elevation="12" style="top: -100px" width="600" class="pa-4">

            <div class="d-flex flex-column align-center">

              <v-img
                  :src="require('../assets/' + providerImage)"
                  width="120"
                  class="mb-4"
                  :contain="issuerName === 'kpn'"
              ></v-img>

              <p class="text-h5">Welcome to {{ providerName }}</p>

              <!-- HEIGHT FILLER -->
              <div style="height: 40px"></div>

              <!-- OAuth Issuer Login Button -->
              <v-btn color="primary" outlined @click="onLoginClicked" height="56" class="px-2 text-none">
                <template v-if="issuerName === 'kpn'">
                  <span>Login with</span>
                  <v-img
                      :src="require('../assets/' + issuerImage)"
                      max-height="40"
                      width="60"
                      contain
                      class="ml-2"
                  ></v-img>
                </template>
                <template v-else>
                  <v-img
                      :src="require('../assets/' + issuerImage)"
                      max-height="40"
                      width="40"
                      contain
                      class="mr-2"
                  ></v-img>
                  <span>
                    {{ $t("login.card.button") }}
                  </span>
                </template>
              </v-btn>
            </div>

          </v-card>

        </v-fade-transition>

      </v-col>

    </v-row>

  </v-container>

</template>

<script>
import {StrongpinOAuth} from "@/utils/oauth";
import IdigiLoginCard from "@/components/idigi/LoginCard";

export default {
  name: "LoginPage",

  components: {
    IdigiLoginCard
  },

  data: () => ({
    issuerName: "",
    issuerImage: "",
    loaded: false,
    providerImage: "",
    providerName: "",
  }),

  methods: {

    onLoginClicked() {
      StrongpinOAuth.start();
    }

  },

  created() {
    this.issuerName = config.OAUTH_ISSUER_NAME;
    this.issuerImage = config.OAUTH_ISSUER_LOGO;
    this.providerImage = config.PROVIDER_IMAGE;
    this.providerName = config.PROVIDER_NAME;
  },

  mounted() {
    setTimeout(() => {
      this.loaded = true;
    }, 500);
  },

}
</script>

<style scoped>

</style>