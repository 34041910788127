import { render, staticRenderFns } from "./DocumentPage.vue?vue&type=template&id=aab93970&scoped=true&"
import script from "./DocumentPage.vue?vue&type=script&lang=js&"
export * from "./DocumentPage.vue?vue&type=script&lang=js&"
import style0 from "./DocumentPage.vue?vue&type=style&index=0&id=aab93970&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aab93970",
  null
  
)

/* custom blocks */
import block0 from "./DocumentPage.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports